import ApiService from "./ApiService";
import { BaseApplicationService } from "./BaseApplicationService";
import { Budget } from "../entities/budget.entity";
import { UserWork } from "../entities/user-work";
import { AxiosResponse } from "axios";

type Params = { page?: number; pageSize?: number; filters?: any };

interface UserWorkResponse {
  data: UserWork[];
}

export class BudgetService extends BaseApplicationService<Budget, Params> {
  async find(
    params?: Params
  ): Promise<{ data: Budget[]; totalCount: number; pageSize: number }> {
    const { page = 1, pageSize = 20, filters } = params || {};
    const response = await ApiService.get(`/api_v2/budget-workshop/index`, {
      params: {
        page,
        pageSize,
        ...filters,
      },
    });

    const data = response?.data.data.map((p: Budget) => new Budget(p)) || [];
    const totalCount = parseInt(
      response.headers?.["x-pagination-total-count"] || "0",
      10
    );

    const perPage = parseInt(
      response.headers?.["x-pagination-per-page"] || "20",
      10
    );

    return { data, totalCount, pageSize: perPage };
  }

  async findByStatesPaginated(
    statesToInclude: number[],
    searchTerm: string | undefined,
    page: number,
    pageSize = 20
  ): Promise<{ data: Budget[]; totalCount: number; pageSize: number }> {
    const filters: Record<string, unknown> = {
      "BudgetSearch[workshop_budget_state_id][]": statesToInclude,
    };

    if (searchTerm && searchTerm.trim() !== "") {
      filters.q = searchTerm;
    }

    return this.find({
      page,
      pageSize,
      filters,
    });
  }

  async update(budgetId: number, budget: Partial<Budget>): Promise<Budget> {
    try {
      const response = await ApiService.post(
        `/api_v2/budget-workshop/update?id=${budgetId}`,
        budget
      );

      if (response.data && response.data.success === false) {
        throw new Error(response.data.data);
      }

      if (!response.data || !response.data.data) {
        throw new Error("Unexpected response format");
      }

      return new Budget(response.data.data);
    } catch (error: any) {
      console.error("Error in BudgetService.update:", error);
      throw new Error(error.message || "Unknown error");
    }
  }

  async updateMechanics(budgetId: number, userIds: number[]): Promise<Budget> {
    if (!budgetId) {
      throw new Error("Budget ID is invalid.");
    }

    const data = {
      budget_id: budgetId,
      user_ids: userIds,
    };

    try {
      const response = await ApiService.post<{
        success: boolean;
        data: Budget;
      }>(`/api_v2/budget/update-mechanics`, data);

      if (!response.data || !response.data.data) {
        throw new Error("Unexpected response format");
      }

      return new Budget(response.data.data);
    } catch (error: any) {
      if (error.response) {
        throw new Error(
          error.response.data.error || "Error al asignar mecánicos"
        );
      } else if (error.request) {
        throw new Error("No se recibió respuesta del servidor");
      } else {
        throw new Error("Error al asignar mecánicos");
      }
    }
  }

  async userWorkList(budgetId: number): Promise<UserWork[]> {
    if (!budgetId) {
      throw new Error("Budget ID is invalid.");
    }

    try {
      const response: AxiosResponse<UserWorkResponse> = await ApiService.get(
        `/api_v2/user-work/index`,
        {
          params: {
            budget_id: budgetId,
          },
        }
      );

      return response.data.data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
      throw new Error("Error al enlistar trabajos asignados");
    }
  }
}
