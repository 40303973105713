import ApiService, { fetchAllPages } from "./ApiService";
import { BaseApplicationService } from "./BaseApplicationService";
import { WorkshopPurchase } from "../entities/workshop-purchase.entity";
import { Comment } from "../entities/comment.entity";
import { PageResponse } from "../types/PagedData";
import { Workshop } from "../entities/workshop.entity";
import { PartBrand, Provider } from "../entities/named.entity";
import { Part } from "../entities/part.entity";
import { AxiosResponse } from "axios";

type Params = {
  page?: number;
  pageSize?: number;
  filters?: Record<string, unknown>;
};

interface UpdatePartResponse {
  success: boolean;
  data: Part;
}

type PartFilter = {
  part_brand_id?: { eq: number };
  brand_id?: { eq: number | undefined };
  car_model_id?: { in: (number | null)[] };
  car_version_id?: { in: (number | null)[] };
  repair_subitem_id?: { eq: number };
};

interface GetPartResponse {
  success: boolean;
  data: Array<{
    id: number;
    part_brand_id: number;
    brand_id: number | undefined;
    car_model_id: number | null;
    car_version_id: number | null;
    repair_subitem_id: number;
    part_number: string;
  }>;
}

interface PaginationParams {
  page: number;
  id?: number;
  name?: string;
}

type GetProviderParams = PaginationParams;
type GetPartBrandParams = PaginationParams;

export class WorkshopPurchaseService extends BaseApplicationService<
  WorkshopPurchase,
  Params
> {
  async find(params?: Params): Promise<PageResponse<WorkshopPurchase>> {
    const { page = 1, pageSize = 20, filters } = params || {};

    const response = await ApiService.get<PageResponse<WorkshopPurchase>>(
      "/api_v2/workshop-purchase/index",
      {
        params: { page, pageSize, ...filters },
      }
    );

    const filteredBudgetList = response?.data.data.filter(
      (b: WorkshopPurchase) => b.budget
    );

    const purchases =
      filteredBudgetList.map(
        (p: WorkshopPurchase) => new WorkshopPurchase(p)
      ) || [];

    const totalCount = parseInt(
      response.headers?.["x-pagination-total-count"] || "0",
      10
    );

    const perPage = parseInt(
      response.headers?.["x-pagination-per-page"] || "20",
      10
    );

    return { data: purchases, totalCount, pageSize: perPage };
  }

  async findByStatesPaginated(
    stateIds: number[],
    searchTerm: string | undefined,
    page: number,
    pageSize = 20
  ): Promise<PageResponse<WorkshopPurchase>> {
    const filters: Record<string, unknown> = {
      workshop_purchase_state_id: stateIds,
    };

    if (searchTerm && searchTerm.trim() !== "") {
      filters.q = searchTerm;
    }

    return this.find({
      page,
      pageSize,
      filters,
    });
  }

  async findOneById(id: number): Promise<WorkshopPurchase | undefined> {
    const response = await ApiService.get(
      `/api_v2/workshop-purchase/view?id=${id}`
    );

    const purchase = response?.data.data;

    return purchase ? new WorkshopPurchase(purchase) : undefined;
  }

  async findByBudgetId(budgetId: number) {
    try {
      const response = await ApiService.get(
        `/api_v2/workshop-purchase/index?budget_id=${budgetId}`
      );

      const purchase = Array.isArray(response.data.data)
        ? response.data.data[0]
        : response.data.data;

      if (purchase?.budget) {
        return new WorkshopPurchase(purchase);
      }

      return undefined;
    } catch (error) {
      console.error(`Error fetching purchase for budgetId ${budgetId}:`, error);
      throw error;
    }
  }

  findOne(_params: Params): Promise<WorkshopPurchase> {
    throw new Error("Method not implemented.");
  }

  async history(purchaseId: number[], purchaseItemIds: number[] = []) {
    const models: Record<string, number[]> = {
      "backend\\models\\WorkshopPurchase": purchaseId,
    };

    if (purchaseItemIds.length > 0) {
      models["backend\\models\\WorkshopPurchaseItem"] = purchaseItemIds;
    }

    const filters = { models };

    const allHistoryData = await fetchAllPages<any>(
      `/api_v2/audit-trail/index`,
      20,
      undefined,
      "POST",
      filters
    );
    return allHistoryData;
  }

  async getComments(id: number) {
    const response = await ApiService.get(
      `/api_v2/workshop-purchase/comments?id=${id}`
    );

    const comments = response?.data.data.map(
      (c: any) =>
        new Comment({
          ...c,
          created_at: new Date(c.created_at),
        })
    );

    const sortedComments = comments.sort(
      (a: any, b: any) => b.created_at - a.created_at
    );

    return sortedComments;
  }

  async update(
    purchaseId: number,
    purchase: Partial<WorkshopPurchase>
  ): Promise<WorkshopPurchase> {
    try {
      const response = await ApiService.post(
        `/api_v2/workshop-purchase/update?id=${purchaseId}`,
        purchase
      );

      if (response.data && response.data.success === false) {
        throw new Error(response.data.data);
      }

      if (!response.data || !response.data.data) {
        throw new Error("Unexpected response format");
      }

      return new WorkshopPurchase(response.data.data);
    } catch (error: any) {
      console.error("Error in WorkshopPurchaseService.update:", error);
      throw new Error(error.message || "Unknown error");
    }
  }

  async updatePart(partData: {
    part_brand_id: number | null;
    brand_id: number;
    car_model_id: number | null;
    car_version_id: number | null;
    repair_subitem_id: number;
    part_number: string | null;
  }): Promise<Part> {
    try {
      const response: AxiosResponse<UpdatePartResponse> = await ApiService.post(
        "/api_v2/part/update",
        partData
      );

      if (response.data.success) {
        return response.data.data;
      } else {
        throw new Error(
          "Error al actualizar o crear la parte." +
            JSON.stringify(response.data)
        );
      }
    } catch (error) {
      console.error("Error en la actualización/creación de la parte:", error);
      throw error;
    }
  }

  async getPart(filter: PartFilter): Promise<GetPartResponse> {
    try {
      const response = await ApiService.post<GetPartResponse>(
        "/api_v2/part/index",
        { filter }
      );

      if (response.data.success) {
        return response.data;
      } else {
        throw new Error("Error al obtener partes: operación no exitosa.");
      }
    } catch (error) {
      console.error("Error al obtener partes:", error);
      throw error;
    }
  }

  async getPartBrands(): Promise<{ id: number; name: string }[]> {
    try {
      return fetchAllPages<{ id: number; name: string }>(
        "/api_v2/part-brand/index"
      );
    } catch (error) {
      console.error("Error al obtener las marcas de partes:", error);
      throw error;
    }
  }

  async getPartBrandsPage(): Promise<{ id: number; name: string }[]> {
    try {
      const PartBrandsPage = await ApiService.get("/api_v2/part-brand/index");
      return PartBrandsPage.data.data;
    } catch (error) {
      console.error("Error al obtener las marcas de partes:", error);
      throw error;
    }
  }

  async getPartBrandIndex(
    pageParam = 1,
    id: number | null = null,
    name: string | null = null
  ): Promise<PageResponse<PartBrand>> {
    try {
      const params: GetPartBrandParams = { page: pageParam };

      if (id) {
        params.id = Number(id);
      }

      if (name) {
        params.name = name;
      }

      const response = await ApiService.get<{ data: PartBrand[] }>(
        "/api_v2/part-brand/index",
        { params }
      );

      const PartBrands: PartBrand[] = response.data.data.map(
        (partBrandData) => new PartBrand(partBrandData)
      );

      const totalCount =
        Number(response.headers["x-pagination-total-count"]) || 0;
      const pageSize = Number(response.headers["x-pagination-per-page"]) || 20;
      const currentPage =
        Number(response.headers["x-pagination-current-page"]) || pageParam;

      return {
        data: PartBrands,
        totalCount,
        pageSize,
        currentPage,
      };
    } catch (error) {
      console.error("Error al obtener los proveedores:", error);
      return { data: [], totalCount: 0, pageSize: 20, currentPage: pageParam };
    }
  }

  async getRepairSubItems(): Promise<{ id: number; name: string }[]> {
    try {
      const allSubItems = await fetchAllPages<{
        id: number;
        repair_id: number;
        name: string;
      }>("api_v2/repair-subitem/index");

      return allSubItems;
    } catch (error) {
      console.error("Error al obtener las marcas de partes:", error);
      throw error;
    }
  }

  async getAllBrands() {
    try {
      return fetchAllPages("/api_v2/brand/index");
    } catch (error) {
      console.error("Error al obtener las marcas del vehículo:", error);
      return [];
    }
  }

  async getAllCarModels() {
    try {
      return fetchAllPages("/api_v2/car-model/index");
    } catch (error) {
      console.error("Error al obtener los modelos del vehículo:", error);
      return [];
    }
  }

  async getAllCarVersions() {
    try {
      return fetchAllPages("/api_v2/car-version/index");
    } catch (error) {
      console.error("Error al obtener los modelos del vehículo:", error);
      return [];
    }
  }

  async getWorkshopIndex(): Promise<Workshop[]> {
    try {
      return fetchAllPages("/api_v2/workshop/index");
    } catch (error) {
      console.error("Error al obtener los talleres asociados:", error);
      return [];
    }
  }

  async getProviderIndex(
    pageParam = 1,
    id: number | null = null,
    name: string | null = null
  ): Promise<PageResponse<Provider>> {
    try {
      const params: GetProviderParams = { page: pageParam };

      if (id) {
        params.id = Number(id);
      }

      if (name) {
        params.name = name;
      }

      const response = await ApiService.get<{ data: Provider[] }>(
        "/api_v2/provider/index",
        {
          params,
        }
      );

      const providers: Provider[] = response.data.data.map(
        (providerData) => new Provider(providerData)
      );

      const totalCount =
        Number(response.headers["x-pagination-total-count"]) || 0;
      const pageSize = Number(response.headers["x-pagination-per-page"]) || 20;
      const currentPage =
        Number(response.headers["x-pagination-current-page"]) || pageParam;

      return {
        data: providers,
        totalCount,
        pageSize,
        currentPage,
      };
    } catch (error) {
      console.error("Error al obtener los proveedores:", error);
      return { data: [], totalCount: 0, pageSize: 20, currentPage: pageParam };
    }
  }
}
