import { useQuery } from "@tanstack/react-query";
import { WorkshopPurchaseService } from "../services/WorkshopPurchaseService";

const usePartBrands = () => {
  const workshopPurchaseService = new WorkshopPurchaseService();

  return useQuery({
    queryKey: ["partBrands"],
    queryFn: () => workshopPurchaseService.getPartBrands(),
    staleTime: 5 * 60 * 1000,
  });
};

export default usePartBrands;
