import { Autocomplete, TextField } from "@mui/material";
import { GridFilterInputValueProps } from "@mui/x-data-grid";
import { SyntheticEvent, useCallback, useRef } from "react";

interface AutocompleteFilterProps<T>
  extends Partial<GridFilterInputValueProps> {
  label?: string;
  loading: boolean;
  options: T[];
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => string | number;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  value?: T | null;
  onChangeValue?: (newValue: T | null) => void;
  textFieldVariant?: "standard" | "outlined" | "filled";
  textFieldsize?: "small" | "medium";
  onInputChange?: (event: SyntheticEvent, newValue: string) => void;
  onOpen?: () => void;
  onClose?: () => void;
  disable?: boolean;
}

export default function AutocompleteFilter<T>(
  props: AutocompleteFilterProps<T>
) {
  const {
    // Custom
    value,
    onChangeValue,

    // Commons
    label,
    loading,
    options,
    getOptionLabel,
    getOptionValue,
    textFieldVariant = "standard",
    textFieldsize = "medium",
    onInputChange,
    onOpen,
    onClose,
    disable,

    // Infinity Scroll
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = props;

  // Add both debounce timer refs
  const debounceTimerRef = useRef<NodeJS.Timeout>();
  const scrollDebounceTimerRef = useRef<NodeJS.Timeout>();

  // Create debounced input change handler
  const handleInputChange = useCallback(
    (event: SyntheticEvent, newValue: string) => {
      if (onInputChange) {
        // Clear any existing timer
        if (debounceTimerRef.current) {
          clearTimeout(debounceTimerRef.current);
        }

        // Set new timer
        debounceTimerRef.current = setTimeout(() => {
          onInputChange(event, newValue);
        }, 300); // 300ms debounce delay
      }
    },
    [onInputChange]
  );

  const handleChange = (
    _event: SyntheticEvent<Element, Event>,
    newOption: T | null
  ) => {
    if (onChangeValue) {
      onChangeValue(newOption);
    }
  };

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLUListElement>) => {
      const listbox = event.currentTarget;

      // Add null check before accessing scroll properties
      if (!listbox) return;

      const isNearBottom =
        listbox.scrollTop + listbox.clientHeight >= listbox.scrollHeight - 10;

      if (isNearBottom && fetchNextPage && hasNextPage && !isFetchingNextPage) {
        // Clear any existing scroll timer
        if (scrollDebounceTimerRef.current) {
          clearTimeout(scrollDebounceTimerRef.current);
        }

        // Debounce the fetch operation
        scrollDebounceTimerRef.current = setTimeout(() => {
          fetchNextPage();
        }, 150);
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  return (
    <Autocomplete<T>
      sx={{ width: "auto" }}
      loading={loading}
      options={options}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onOpen={onOpen}
      onClose={onClose}
      isOptionEqualToValue={(option, value) =>
        getOptionValue(option) === getOptionValue(value)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={textFieldVariant}
          sx={{ minWidth: "200px" }}
          size={textFieldsize}
        />
      )}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      renderOption={(props, option) => (
        <li {...props} key={getOptionValue(option)}>
          {getOptionLabel(option)}
        </li>
      )}
      disabled={disable}
    />
  );
}
