import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { BudgetService } from "../../../services/BudgetService";
import { Budget } from "../../../entities/budget.entity";
import { WorkshopService } from "../../../services/WorkshopService";
import { User } from "../../../entities/user.entity";
import { UserWorkTask } from "../../../entities/user-work-task.entity";
import { formatUTCDate } from "../../../utils/dateUtils";
import { useEffect } from "react";
import { queryClient } from "../../../main";

interface MechanicWorkRecordsProps {
  budget: Budget;
}
const UserWorkGrid = ({ budget }: MechanicWorkRecordsProps) => {
  const budgetService = new BudgetService();
  const workshopService = new WorkshopService();

  const { data: userWorkList, isLoading: loadingUserWorkList } = useQuery({
    queryKey: ["userWorkList"],
    queryFn: async () => {
      const response = await budgetService.userWorkList(budget.id);

      return response;
    },
  });

  const { data: users, isLoading: loadingUser } = useQuery({
    queryKey: ["users"],
    queryFn: () => workshopService.users(budget.workshop.id),
  });

  useEffect(() => {
    void queryClient.invalidateQueries({ queryKey: ["userWorkList"] });
  }, [budget]);

  const initialColumns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "user_id",
      headerName: "Mecánico",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<{ user_id: number }>) => {
        if (!users) return "";

        if (budget) {
          if (Array.isArray(users)) {
            const mechanics = users.find(
              (mechanic: User) => mechanic.id === params.row.user_id
            );

            return mechanics ? `${mechanics.name} ${mechanics.lastname}` : "";
          }
        }
        return "";
      },
    },
    { field: "budget_id", headerName: "Presupuesto", flex: 1 },
    {
      field: "start_date",
      headerName: "Fecha de Inicio",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<{ start_date: Date }>) =>
        formatUTCDate(params.row.start_date),
    },
    {
      field: "end_date",
      headerName: "Fecha de Fin",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<{ end_date: Date }>) =>
        formatUTCDate(params.row.end_date),
    },
    {
      field: "task_id",
      headerName: "Tarea",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<{ task_id: number }>) =>
        UserWorkTask.getLabelById(params.row.task_id),
    },
    { field: "comments", headerName: "Comentarios", flex: 1 },
  ];

  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      <DataGrid
        rows={userWorkList || []}
        columns={initialColumns}
        loading={loadingUserWorkList || loadingUser}
      />
    </Box>
  );
};

export default UserWorkGrid;
