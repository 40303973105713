import ApiService from "./ApiService";
import { BaseApplicationService } from "./BaseApplicationService";
import { WorkshopPurchaseState } from "../entities/workshop-purchase-state.entity";

type Params = {};

export class WorkshopPurchaseStateService
  extends BaseApplicationService<WorkshopPurchaseState, Params> {
  async find(_params: Params): Promise<WorkshopPurchaseState[]> {
    const response = await ApiService.get("/api_v2/workshop-purchase-state");

    return (
      response?.data.data.map(
        (p: WorkshopPurchaseState) => new WorkshopPurchaseState(p)
      ) || []
    );
  }
}
