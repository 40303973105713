import { Box } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { WorkshopPurchaseItem } from "../../entities/workshop-purchase-item.entity";
import { PurchaseContext } from "../Pages/Purchase/context/PurchaseContext";
import { WorkshopPurchaseService } from "../../services/WorkshopPurchaseService";
import { WorkshopPurchaseItemService } from "../../services/WorkshopPurchaseItemService";
import AutocompleteFilter from "../Pages/Purchase/AutocompleteFilter";
import { Provider } from "../../entities/named.entity";
import { useInfiniteQuery } from "@tanstack/react-query";
import { PageResponse } from "../../types/PagedData";

interface ProviderProps {
  item: WorkshopPurchaseItem;
}
const workshopPurchaseService = new WorkshopPurchaseService();

export default function ProviderDropdownField({ item }: ProviderProps) {
  const itemService = new WorkshopPurchaseItemService();
  const { updatePurchase, selectedPurchase } = useContext(PurchaseContext);
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(
    null
  );
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const {
    data: providerData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingProvider,
    refetch: refetchProviders,
  } = useInfiniteQuery<PageResponse<Provider>>({
    queryKey: ["provider", searchTerm],
    queryFn: (context) =>
      workshopPurchaseService.getProviderIndex(
        context.pageParam as number,
        null,
        searchTerm
      ),
    getNextPageParam: (lastPage) => {
      const { totalCount, pageSize, currentPage } = lastPage;
      const totalPages = Math.ceil(totalCount / pageSize);
      return currentPage && currentPage < totalPages
        ? currentPage + 1
        : undefined;
    },
    initialPageParam: 1,
    //staleTime: 1000 * 60 * 5,
  });

  const providers: Provider[] = useMemo(
    () => providerData?.pages.flatMap((page) => page.data) || [],
    [providerData]
  );

  const handleFetchNextPage = () => {
    fetchNextPage().catch((error) =>
      console.error("Error en fetchNextPage:", error)
    );
  };

  const setProviderById = async (id: number | null) => {
    if (!id) {
      setSelectedProvider(null);
      return;
    }
    try {
      const response = await workshopPurchaseService.getProviderIndex(
        1,
        id,
        null
      );
      const providerById = response.data.find((p) => p.id === id);
      setSelectedProvider(providerById || null);
    } catch (error) {
      console.error("Error fetching provider by id:", error);
      setSelectedProvider(null);
    }
  };

  const combinedProviders: Provider[] = useMemo(() => {
    return selectedProvider &&
      !providers.find((p) => p.id === selectedProvider.id)
      ? [selectedProvider, ...providers]
      : providers;
  }, [selectedProvider, providers]);

  useEffect(() => {
    if (searchTerm !== null) {
      return;
    }
    if (!item.workshop_id) {
      setSelectedProvider(null);
      return;
    }
    const foundProvider = combinedProviders.find(
      (p) => p.id === item.workshop_id
    );
    if (foundProvider) {
      setSelectedProvider(foundProvider);
    } else {
      void setProviderById(item.workshop_id);
    }
  }, [item.workshop_id, combinedProviders, searchTerm]);

  const updateItemInPurchase = async (updatedItem: WorkshopPurchaseItem) => {
    if (!selectedPurchase) return;
    const updatedItems = selectedPurchase.workshopPurchaseItems.map(
      (currentItem) =>
        currentItem.id === updatedItem.id ? updatedItem : currentItem
    );

    const newPurchase = {
      ...selectedPurchase,
      workshopPurchaseItems: updatedItems,
    };

    try {
      await updatePurchase(selectedPurchase.id, newPurchase);
    } catch (error) {
      console.error("Error al actualizar el item en la compra", error);
      enqueueSnackbar("Error al actualizar el item en la compra", {
        variant: "error",
      });
    }
  };

  const onProviderChangeById = async (newWorkshopId: number | null) => {
    try {
      const result = await itemService.update(item.id, {
        workshop_id: newWorkshopId,
      });
      if ("errors" in result) {
        enqueueSnackbar("Error al actualizar el proveedor", {
          variant: "error",
        });
        console.error("Error al actualizar el proveedor:", result.errors);
        return false;
      }

      const updatedItem = { ...item, workshop_id: newWorkshopId };

      await updateItemInPurchase(updatedItem);

      enqueueSnackbar("Proveedor actualizado con éxito", {
        variant: "success",
      });
      return true;
    } catch (error) {
      console.error("Error al actualizar workshop_id:", error);
      enqueueSnackbar("Error inesperado al actualizar el taller", {
        variant: "error",
      });
      return false;
    }
  };

  const handleChangeValue = (newOption: Provider | null) => {
    const id = newOption ? newOption.id : null;
    onProviderChangeById(id)
      .then((result) => {
        if (result) {
          void setProviderById(id);
        }
      })
      .catch((error) => console.error("Error en handleChangeValue:", error));
  };

  const handleOpen = () => {
    setSearchTerm("");
    void refetchProviders();
  };

  const handleClose = () => {
    setSearchTerm("");
  };

  return (
    <Box>
      <AutocompleteFilter
        label="Proveedores"
        textFieldVariant="outlined"
        textFieldsize="small"
        loading={isLoadingProvider || isFetchingNextPage}
        options={combinedProviders}
        getOptionLabel={(option: Provider) => option.name}
        getOptionValue={(option: Provider) => option.id}
        value={selectedProvider}
        onChangeValue={handleChangeValue}
        fetchNextPage={handleFetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onInputChange={(_event, searchTerm) => {
          setSearchTerm(searchTerm);
          void refetchProviders();
        }}
        onOpen={handleOpen}
        onClose={handleClose}
      />
    </Box>
  );
}
