import { PurchaseContext } from "./PurchaseContext";
import { useEffect, useState } from "react";
import { PurchaseProps } from "./PurchaseContext";
import { WorkshopPurchase } from "../../../../entities/workshop-purchase.entity";
import { WorkshopPurchaseService } from "../../../../services/WorkshopPurchaseService";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

interface PurchaseProviderProps {
  children: JSX.Element | JSX.Element[];
}

interface PurchasesResponse {
  data: WorkshopPurchase[];
  totalCount: number;
  pageSize: number;
}

export const PurchaseProvider = ({ children }: PurchaseProviderProps) => {
  const queryClient = useQueryClient();
  const purchaseService = new WorkshopPurchaseService();

  const [open, setOpen] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState<
    undefined | WorkshopPurchase
  >(undefined);
  const [filters, setFilters] = useState<any>({});
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);

  const {
    data: queryData,
    isLoading: loadingPurchases,
    isPlaceholderData,
  } = useQuery<PurchasesResponse, Error>({
    queryKey: ["purchases", filters, page, pageSize],
    queryFn: async () => {
      const response = await purchaseService.find({
        page,
        pageSize,
        filters,
      });
      return response;
    },
    placeholderData: keepPreviousData,
  });

  const data = queryData ?? { data: [], totalCount: 0, pageSize: 20 };

  const { data: purchases, totalCount } = data;

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: ["purchases", filters, page + 1, pageSize],
      queryFn: () =>
        purchaseService.find({ page: page + 1, pageSize, filters }),
    });
  }, [page, filters, pageSize, queryClient]);

  const replacePurchaseGlobally = (
    purchaseId: number,
    updatedPurchase: WorkshopPurchase
  ) => {
    queryClient.setQueriesData<PurchasesResponse>(
      { queryKey: ["purchases", "purchasesByState"], exact: false },
      (oldData) => {
        if (!oldData) return oldData;

        const updatedPurchases = oldData.data.map((purchase) =>
          purchase.id === purchaseId ? updatedPurchase : purchase
        );

        return {
          ...oldData,
          data: updatedPurchases,
        };
      }
    );
  };

  const updatePurchase = async (
    purchaseId: number,
    updatedPurchaseData: Partial<WorkshopPurchase>
  ) => {
    try {
      replacePurchaseGlobally(purchaseId, {
        ...selectedPurchase,
        ...updatedPurchaseData,
      } as WorkshopPurchase);

      const updatedPurchase = await purchaseService.update(
        purchaseId,
        updatedPurchaseData
      );

      replacePurchaseGlobally(purchaseId, updatedPurchase);

      void queryClient.invalidateQueries({ queryKey: ["purchases"] });
      void queryClient.invalidateQueries({ queryKey: ["purchasesByState"] });

      if (selectedPurchase?.id === purchaseId) {
        setSelectedPurchase(updatedPurchase);
      }
    } catch (error) {
      console.error("Error updating purchase:", error);
    }
  };

  const purchaseContextValue: PurchaseProps = {
    open,
    setOpen,
    selectedPurchase,
    setSelectedPurchase,
    purchases,
    setPurchases: () => {},
    loading: loadingPurchases,
    setLoading: () => {},
    totalCount,
    setFilters,
    pageSize,
    setPageSize,
    page,
    setPage,
    updatePurchase,
    isPlaceholderData,
    filters,
  };

  return (
    <PurchaseContext.Provider value={purchaseContextValue}>
      {children}
    </PurchaseContext.Provider>
  );
};
