import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterItem,
  GridFilterModel,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useMemo, useState } from "react";

import { WorkshopPurchaseItemService } from "../../../services/WorkshopPurchaseItemService";
import {
  WorkshopPurchaseItemInfo,
  WorkshopPurchaseItemState,
} from "../../../entities/workshop-purchase-item-state.entity";
import { FormattedNumber } from "react-intl";
import AppToolbarExport from "../../Common/DataGrid/AppToolbarExport";
import { formatUTCDate } from "../../../utils/dateUtils";
import "../../../index.css";
import { User } from "../../../entities/user.entity";
import { WorkshopService } from "../../../services/WorkshopService";
import { keepPreviousData, useQueries, useQuery } from "@tanstack/react-query";
import { WorkshopPurchaseService } from "../../../services/WorkshopPurchaseService";
import AutocompleteFilter from "./AutocompleteFilter";
import { Car } from "../../../entities/car.entity";
import {
  ExtendedGridFilterItem,
  VehicleFilterInput,
} from "./VehicleFilterInput";
import { WorkshopPurchase } from "../../../entities/workshop-purchase.entity";
import { WorkshopBudgetState } from "../../../entities/workshop-budget-state";
import { CarBrand } from "../../../entities/named.entity";
import { HistoricalReportItem } from "../../../entities/report-item.entity";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <AppToolbarExport />
    </GridToolbarContainer>
  );
}

interface Filter {
  budget_id?: string;
  identification?: string;
  workshop_purchase_id?: string;
  budgetItem?: string;
  id?: string;
  repairSubitem?: string;
  brand?: string;
  description?: string;
  responsible?: string;
  budget_expiration_days?: string;
  quantity?: string;
  price?: string;
}

const service = new WorkshopPurchaseItemService();
const workshopPurchaseService = new WorkshopPurchaseService();
const workshopService = new WorkshopService();

export default function Report() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(() => {
      const visibilityModel: GridColumnVisibilityModel = {};
      WorkshopPurchaseItemState.getIdAndLabels().forEach(
        (item: WorkshopPurchaseItemInfo) => {
          visibilityModel[`state_${item.id}`] = false;
        }
      );
      return visibilityModel;
    });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [filters, setFilters] = useState<Filter>({});

  const { data: reportData, isLoading } = useQuery({
    queryKey: ["reportData", filters, page, pageSize],
    queryFn: async () => {
      const response = await service.getHistoricalReportData(page + 1, filters);
      return response;
    },
    placeholderData: keepPreviousData,
  });

  const budgetIds = useMemo(() => {
    const ids = (reportData?.data || [])
      .map((row: HistoricalReportItem) => row.budgetItem?.budget_id)
      .filter(Boolean);
    return [...new Set(ids)];
  }, [reportData?.data]);

  const purchaseQueries = useQueries({
    queries: budgetIds.map((budgetId) => ({
      queryKey: ["purchase", budgetId],
      queryFn: async () => {
        return workshopPurchaseService.findByBudgetId(budgetId);
      },
      staleTime: 1000 * 60 * 5,
      enabled: !!budgetId,
    })),
  });

  const purchaseMap: Record<number, WorkshopPurchase | undefined> = {};
  purchaseQueries.forEach((query, index) => {
    const data = query.data;
    if (data) {
      purchaseMap[budgetIds[index]] = data;
    }
  });

  const allWorkshopIds = Object.values(purchaseMap)
    .map((purchase) => purchase?.workshop?.id)
    .filter((id): id is number => Boolean(id));
  const uniqueWorkshopIds = [...new Set(allWorkshopIds)];

  const usersQuery = useQuery({
    queryKey: ["users", uniqueWorkshopIds],
    queryFn: async (): Promise<User[]> => {
      const allUsers: User[] = [];
      for (const workshopId of uniqueWorkshopIds) {
        const users = await workshopService.users(workshopId);
        if (!("errors" in users)) {
          allUsers.push(...users);
        }
      }
      return allUsers;
    },
    enabled: uniqueWorkshopIds.length > 0,
    staleTime: 1000 * 60 * 5,
  });

  const cars: Record<number, Car | undefined> = {};
  const responsibleIds: Record<number, number | undefined> = {};
  Object.entries(purchaseMap).forEach(([bId, purchase]) => {
    const budgetId = Number(bId);
    if (purchase) {
      cars[budgetId] = purchase.budget?.car;
      responsibleIds[budgetId] = purchase.responsible_id;
    }
  });

  const isLoadingWorkshopPurchaseData =
    purchaseQueries.some((q) => q.isLoading) || usersQuery.isLoading;
  const isError = purchaseQueries.some((q) => q.isError) || usersQuery.isError;

  const handlePaginationModelChange = (paginationModel: {
    page: number;
    pageSize: number;
  }) => {
    setPage(paginationModel.page);
    setPageSize(paginationModel.pageSize);
  };

  const handleFilterChange = (model: GridFilterModel) => {
    setFilterModel(model);

    const newFilters = model.items.reduce(
      (acc: { [key: string]: unknown }, filter) => {
        const extendedFilter = filter as ExtendedGridFilterItem;
        const field = mapFilterFields(filter.field, extendedFilter.filterType);
        const value = filter.value as string;

        if (value) {
          acc[field] = value;
        }
        return acc;
      },
      {}
    );

    setFilters(newFilters);
    setPage(0);
  };

  const mapFilterFields = (field: string, filterType?: string): string => {
    if (field === "vehicle" && filterType) {
      return filterType;
    }

    const fieldMapping: { [key: string]: string } = {
      workshop_purchase_id: "workshop_purchase_id",
      budgetItem: "budget_id",
      id: "id",
      repairSubitem: "repair_subitem_id",
      brand: "part_brand_id",
      description: "description",
      responsible: "responsible_id",
      budget_expiration_days: "expiration_days",
      quantity: "quantity",
      price: "price",
    };
    return fieldMapping[field] || field;
  };

  const { data: partBrands = [], isLoading: loadingPartBrands } = useQuery({
    queryKey: ["partBrands"],
    queryFn: async () => {
      const response = await workshopPurchaseService.getPartBrands();
      return response;
    },
  });

  const { data: repairSubItems = [], isLoading: loadingSubItems } = useQuery({
    queryKey: ["repairSubItems"],
    queryFn: async () => {
      const response = await workshopPurchaseService.getRepairSubItems();
      return response;
    },
  });

  const { data: brands = [], isLoading: loadingBrands } = useQuery({
    queryKey: ["allBrands"],
    queryFn: () => workshopPurchaseService.getAllBrands(),
    staleTime: 1000 * 60 * 5,
  });

  const { data: carModels = [], isLoading: loadingCarModels } = useQuery({
    queryKey: ["allCarModels"],
    queryFn: () => workshopPurchaseService.getAllCarModels(),
    staleTime: 1000 * 60 * 5,
  });

  const { data: carVersions = [], isLoading: loadingCarVersions } = useQuery({
    queryKey: ["allCarVersions"],
    queryFn: () => workshopPurchaseService.getAllCarVersions(),
    staleTime: 1000 * 60 * 5,
  });

  const initialColumns: GridColDef[] = [
    {
      field: "workshop_purchase_id",
      headerName: "Compra",
      flex: 0.5,
      filterable: true,
    },
    {
      field: "budgetItem",
      headerName: "Presupuesto",
      flex: 0.5,
      filterable: true,
      valueGetter: (
        params: GridValueGetterParams<{ budgetItem?: { budget_id?: string } }>
      ) => {
        return params.row.budgetItem ? params.row.budgetItem.budget_id : "N/A";
      },
    },
    {
      field: "vehicle",
      headerName: "Vehículo",
      flex: 2,
      filterable: true,
      valueGetter: (
        params: GridValueGetterParams<{ budgetItem?: { budget_id?: string } }>
      ) => {
        const budgetId = Number(params.row.budgetItem?.budget_id);

        if (isError) return "Error al cargar";
        if (isLoadingWorkshopPurchaseData) return "Cargando...";

        if (!budgetId) return "N/A";

        const car = cars[budgetId];
        return car ? Car.getSummary(car) : "N/A";
      },
      filterOperators: [
        {
          label: "Contains",
          value: "contains",
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (typeof filterItem.value !== "string" || !filterItem.value)
              return null;

            const filterValue = filterItem.value;

            return (params: GridCellParams<{ vehicle?: string }>) => {
              const value = params.row.vehicle;

              if (typeof value !== "string") return false;

              return value.toLowerCase().includes(filterValue.toLowerCase());
            };
          },
          InputComponent: (props) => (
            <VehicleFilterInput
              {...props}
              brands={brands}
              carModels={carModels}
              carVersions={carVersions}
              loading={loadingBrands || loadingCarModels || loadingCarVersions}
            />
          ),
        },
      ],
    },

    {
      field: "id",
      headerName: "Item ID",
      flex: 0.5,
      filterable: true,
    },
    {
      field: "repairSubitem",
      headerName: "Item",
      flex: 1.5,
      filterable: true,
      filterOperators: [
        {
          label: "Es",
          value: "equals",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => {
              return value === filterItem.value;
            };
          },
          InputComponent: (props) => (
            <AutocompleteFilter<WorkshopBudgetState>
              {...props}
              label="Items de repación"
              loading={loadingSubItems}
              options={repairSubItems}
              getOptionLabel={(option) => (option ? option.name : "")}
              getOptionValue={(option) => (option ? option.id : "")}
            />
          ),
        },
      ],
      valueGetter: (
        params: GridValueGetterParams<{
          budgetItem?: { repairSubitem?: { name?: string } };
        }>
      ) => {
        return params.row.budgetItem
          ? params.row.budgetItem.repairSubitem?.name
          : "N/A";
      },
    },
    {
      field: "price",
      headerName: "Precio",
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ price?: number }>) => (
        <FormattedNumber
          value={params.row.price || 0}
          style="currency"
          currency="ARS"
        />
      ),
    },
    {
      field: "quantity",
      headerName: "Cantidad",
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ quantity?: number }>) => (
        <FormattedNumber value={params.row.quantity || 0} />
      ),
    },
    {
      field: "brand",
      headerName: "Marca de items",
      flex: 1,
      filterable: true,
      filterOperators: [
        {
          label: "Es",
          value: "equals",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => {
              return value === filterItem.value;
            };
          },
          InputComponent: (props) => (
            <AutocompleteFilter<CarBrand>
              {...props}
              label="Filtrar Marca"
              loading={loadingPartBrands}
              options={partBrands}
              getOptionLabel={(option) => (option ? option.name : "")}
              getOptionValue={(option) => (option ? option.id : "")}
            />
          ),
        },
      ],
    },
    {
      field: "description",
      headerName: "Descripción",
      flex: 1,
      filterable: true,
    },
    {
      field: "responsible",
      headerName: "Responsable",
      flex: 1,
      filterable: true,
      valueGetter: (
        params: GridValueGetterParams<{ budgetItem?: { budget_id?: string } }>
      ) => {
        const budgetId = Number(params.row.budgetItem?.budget_id);

        if (isError) return "Error al cargar";
        if (isLoadingWorkshopPurchaseData) return "Cargando...";

        const responsible = responsibleIds[budgetId];
        if (!responsible) return "";

        const user = usersQuery.data?.find((u) => u.id === responsible);
        if (!user) return "";

        return `${user.name} ${user.lastname}`;
      },
      filterOperators: [
        {
          label: "Es",
          value: "equals",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => {
              return value === filterItem.value;
            };
          },
          InputComponent: (props) => (
            <AutocompleteFilter<User>
              {...props}
              label="Filtrar Responsable"
              options={usersQuery.data || []}
              getOptionLabel={(option) =>
                option ? `${option.name} ${option.lastname}` : ""
              }
              getOptionValue={(option) => (option ? option.id : "")}
            />
          ),
        },
      ],
    },
    {
      field: "budget_expiration_days",
      headerName: "Días de expiración",
      flex: 1,
      filterable: true,
      valueGetter: (
        params: GridValueGetterParams<{ budgetItem?: { budget_id?: string } }>
      ) => {
        const budgetId = Number(params.row.budgetItem?.budget_id);

        if (isError) return "Error al cargar";
        if (isLoadingWorkshopPurchaseData) return "Cargando...";
        const purchase = purchaseMap[budgetId];
        if (!purchase) return "";

        return purchase.budget?.expiration_days || "";
      },
    },
  ];

  const getColumns = () => {
    return initialColumns.concat(
      WorkshopPurchaseItemState.getIdAndLabels().map(
        (item: WorkshopPurchaseItemInfo) => ({
          field: `state_${item.id}`,
          headerName: item.label,
          flex: 1,
          cellClassName: "hoverable-column",
          valueGetter: (
            params: GridValueGetterParams<{ [key: string]: string }>
          ) => {
            const stateValue = params.row[`state_${item.id}`];
            return stateValue ? formatUTCDate(stateValue) : "";
          },
        })
      )
    );
  };

  return (
    <>
      <h2>Reporte Item de Compras</h2>
      <DataGrid
        sx={{ minHeight: "300px" }}
        loading={isLoading || isLoadingWorkshopPurchaseData}
        rows={reportData?.data || []}
        columns={getColumns()}
        slots={{
          toolbar: CustomToolbar,
        }}
        pagination
        paginationMode="server"
        rowCount={reportData?.totalCount || 0}
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={handlePaginationModelChange}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        filterMode="server"
        onFilterModelChange={handleFilterChange}
        filterModel={filterModel}
      />
    </>
  );
}
