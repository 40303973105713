import {
  useState,
  ReactNode,
  SetStateAction,
  Dispatch,
  useMemo,
  useEffect,
} from "react";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import { SelectChangeEvent } from "@mui/material";
import { useTimer } from "../../../hooks/useTimer";
import StopTimerDialog from "./StopTimerDialog";

const formatTime = (value: number) => {
  return value.toString().padStart(2, "0");
};

interface TimerCellProps {
  budgetId: number;
  onStatusChange: (budgetId: number, isActive: boolean) => void;
  handleTaskChange: (event: SelectChangeEvent<any>, child: ReactNode) => void;
  selectedTaskId: number | null;
  setSelectedTaskId: Dispatch<SetStateAction<number | null>>;
  resetTimerSignal: { budgetId: number; signal: number } | null;
}

const TimerCell = ({
  budgetId,
  onStatusChange,
  handleTaskChange,
  selectedTaskId,
  setSelectedTaskId,
  resetTimerSignal,
}: TimerCellProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [comment, setComment] = useState("");
  const { isActive, time, handleStartClick, handleStopClick, resetTimer } =
    useTimer(budgetId, onStatusChange);

  const handleStopClickWithDialog = () => {
    setOpenDialog(true);
  };

  const handleSendComment = async () => {
    await handleStopClick(selectedTaskId, comment);
    setSelectedTaskId(null);
    setOpenDialog(false);
    setComment("");
  };

  const handleNoComment = () => {
    setOpenDialog(false);
    void handleSendComment();
  };

  useEffect(() => {
    if (resetTimerSignal && resetTimerSignal.budgetId === budgetId) {
      resetTimer();
    }
  }, [resetTimerSignal, budgetId, resetTimer]);

  const formattedTime = useMemo(() => {
    return `${formatTime(time.hours)}:${formatTime(time.minutes)}:${formatTime(
      time.seconds
    )}`;
  }, [time]);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <span style={{ color: isActive ? "#007bff" : "" }}>{formattedTime}</span>
      {!isActive ? (
        <IconButton aria-label="Play" onClick={() => void handleStartClick()}>
          <PlayArrowIcon />
        </IconButton>
      ) : (
        <IconButton aria-label="Stop" onClick={handleStopClickWithDialog}>
          <StopIcon />
        </IconButton>
      )}

      <StopTimerDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        comment={comment}
        setComment={setComment}
        selectedTaskId={selectedTaskId}
        handleTaskChange={handleTaskChange}
        onSendComment={() => void handleSendComment()}
        onNoComment={handleNoComment}
      />
    </div>
  );
};

export default TimerCell;
