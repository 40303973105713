import { CarBrand, CarModel, CarVersion } from "./named.entity";

export class Car {
  readonly id!: number;
  readonly company_id!: number;
  readonly creation_date!: Date;
  readonly buy_date!: Date;
  readonly brand_id!: number;
  readonly model!: string;
  readonly color!: string;
  readonly identification!: string;
  readonly usage!: number;
  readonly last_update_date!: Date;
  readonly company_cost_center_id!: object;
  readonly company_section_id!: object;
  readonly engine_number!: string;
  readonly chassis_number!: string;
  readonly year!: Date;
  readonly ruta_expiration_date!: Date;
  readonly fire_extinguisher_expiration_date!: Date;
  readonly documentation_expiration_date!: Date;
  readonly inspection_expiration_date!: Date;
  readonly insurance_expiration_date!: Date;
  readonly insurance_name!: string;
  readonly insurance_number!: Date;
  readonly car_type_id!: number;
  readonly fuel_id!: object;
  readonly notification_profile_id!: object;
  readonly notify!: object;
  readonly last_service_date_1!: object;
  readonly last_service_km_1!: object;
  readonly last_service_date_2!: object;
  readonly last_service_km_2!: object;
  readonly last_service_date_3!: object;
  readonly last_service_km_3!: object;
  readonly car_model_id!: object;
  readonly car_version_id!: object;
  readonly in_workshop!: object;
  readonly brand!: CarBrand;
  readonly carModel?: CarModel;
  readonly carVersion?: CarVersion;

  static getSummary(car: Car): string {
    return `${car.identification} ${car.brand.name} ${car.model ?? ""} ${
      car.carVersion?.name ?? ""
    }`;
  }

  constructor(data: Partial<Car>) {
    Object.assign(this, data);
  }
}
