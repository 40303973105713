import { BudgetItem } from "./budget-item.entity";
import { Part } from "./part.entity";
import { WorkshopPurchaseItemState } from "./workshop-purchase-item-state.entity";

export interface LatestPrice {
  id: number;
  date: string | null;
  workshopPurchaseItem: WorkshopPurchaseItem | null;
}

export class WorkshopPurchaseItem {
  readonly id!: number;
  readonly brand?: string;
  readonly workshop_purchase_item_state_id!: number;
  readonly price!: number;
  readonly quantity!: number;
  readonly budgetItem!: BudgetItem;
  readonly workshopPurchaseItemState!: WorkshopPurchaseItemState;
  readonly description!: string;
  readonly has_sample!: boolean;
  readonly part?: Part;
  readonly part_id!: number | null;
  readonly workshop_id?: number | null;
  readonly workshop_purchase_id!: number;
  readonly workshop_purchase_proforma_id!: number | null;

  constructor(data: Partial<WorkshopPurchaseItem>) {
    Object.assign(this, data);
  }
}
