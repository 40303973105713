import ApiService from "./ApiService";
import { User } from "../entities/user.entity";

export class WorkshopService {
  async users(id: number) {
    const response = await ApiService.get(`/api_v2/workshop/users?id=${id}`);

    if (response.data.success == false) {
      return { errors: response.data.data };
    }

    return response.data.data.map((u: any) => new User(u)) as User[];
  }
}
