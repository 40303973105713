export class UserWorkTask {
  static readonly DIAGNOSTIC_ID = 1;
  static readonly MAINTENANCE_ID = 2;
  static readonly REPAIR_ID = 3;
  static readonly REPLACEMENT_ID = 4;
  static readonly ADJUST_ID = 5;

  static readonly DIAGNOSTIC_LABEL = "Diagnóstico";
  static readonly MAINTENANCE_LABEL = "Mantenimiento";
  static readonly REPAIR_LABEL = "Reparación";
  static readonly REPLACEMENT_LABEL = "Reemplazo";
  static readonly ADJUST_LABEL = "Ajuste";

  static readonly TASKS_MAP: Record<number, string> = {
    [UserWorkTask.DIAGNOSTIC_ID]: UserWorkTask.DIAGNOSTIC_LABEL,
    [UserWorkTask.MAINTENANCE_ID]: UserWorkTask.MAINTENANCE_LABEL,
    [UserWorkTask.REPAIR_ID]: UserWorkTask.REPAIR_LABEL,
    [UserWorkTask.REPLACEMENT_ID]: UserWorkTask.REPLACEMENT_LABEL,
    [UserWorkTask.ADJUST_ID]: UserWorkTask.ADJUST_LABEL,
  };

  readonly id!: number;
  readonly name!: string;

  constructor(data: Partial<UserWorkTask>) {
    Object.assign(this, data);
  }

  static getLabelById(id: number): string {
    return this.TASKS_MAP[id] || "Tarea desconocida";
  }

  static getTaskOptions(): { id: number; label: string }[] {
    return Object.entries(this.TASKS_MAP).map(([id, label]) => ({
      id: Number(id),
      label,
    }));
  }
}
