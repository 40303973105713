import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Tooltip,
  Input,
  IconButton,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  ModeEdit as ModeEditIcon,
  AddBox as AddBoxIcon,
  ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";

import { WorkshopPurchaseItem } from "../../entities/workshop-purchase-item.entity";
import { PurchaseContext } from "../Pages/Purchase/context/PurchaseContext";
import { WorkshopPurchaseService } from "../../services/WorkshopPurchaseService";
import { WorkshopPurchaseItemService } from "../../services/WorkshopPurchaseItemService";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { useAppSelector } from "../../redux-store/hooks";
import { PermissionEnum } from "../../enum/permission.enum";
import AutocompleteFilter from "../Pages/Purchase/AutocompleteFilter";
import { PartBrand } from "../../entities/named.entity";
import { Part } from "../../entities/part.entity";
import { RootState } from "../../redux-store";
import { PageResponse } from "../../types/PagedData";
import useDebounce from "../../hooks/useDebounce";
import { RepairSubitem } from "../../entities/repair-subitem.entity";

interface PurchaseItemRowProps {
  item: WorkshopPurchaseItem;
  isInputDisabled: boolean;
}

const workshopPurchaseService = new WorkshopPurchaseService();
const workshopPurchaseItemService = new WorkshopPurchaseItemService();

export default function PartBrandDropdownField({
  item,
  isInputDisabled,
}: PurchaseItemRowProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { updatePurchase, selectedPurchase } = useContext(PurchaseContext);
  const user = useAppSelector((state: RootState) => state.userReducer.user);
  const car = selectedPurchase?.budget.car;

  const [isPartNumberVisible, setIsPartNumberVisible] = useState(false);
  const [partNumber, setPartNumber] = useState<string>(
    item.part?.part_number || ""
  );
  const [selectedBrandId, setSelectedBrandId] = useState<number | undefined>(
    item.part?.part_brand_id || undefined
  );
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const debouncedSearchTerm = useDebounce<string>(inputValue, 500);

  const {
    data: partBrandData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingPartBrand,
    refetch: refetchPartBrands,
  } = useInfiniteQuery<PageResponse<PartBrand>>({
    queryKey: ["partBrand", debouncedSearchTerm],
    queryFn: (context) => {
      return workshopPurchaseService.getPartBrandIndex(
        context.pageParam as number,
        null,
        debouncedSearchTerm
      );
    },
    getNextPageParam: (lastPage) => {
      const { totalCount, pageSize, currentPage } = lastPage;
      const totalPages = Math.ceil(totalCount / pageSize);

      return currentPage && currentPage < totalPages
        ? currentPage + 1
        : undefined;
    },
    initialPageParam: 1,
  });

  /**
   * Query to get the selected brand (if any) using its id.
   * Runs only when there is a selectedBrandId.
   */
  const { data: selectedBrandResponse } = useQuery({
    queryKey: ["selectedPartBrand", selectedBrandId],
    queryFn: () => {
      if (!selectedBrandId) return Promise.resolve(null);
      return workshopPurchaseService.getPartBrandIndex(
        1,
        selectedBrandId,
        null
      );
    },
    enabled: !!selectedBrandId,
  });

  const selectedBrand = useMemo(
    () => selectedBrandResponse?.data?.[0] ?? null,
    [selectedBrandResponse]
  );

  /**
   * The brands in the list and the selected brand (if not already included) are merged.
   */
  const partBrands: PartBrand[] = useMemo(() => {
    const list = partBrandData?.pages.flatMap((page) => page.data) || [];
    if (selectedBrand && !list.some((brand) => brand.id === selectedBrand.id)) {
      return [selectedBrand, ...list];
    }
    return list;
  }, [partBrandData, selectedBrand]);

  useEffect(() => {
    setSelectedBrandId(item.part?.part_brand_id || undefined);
    setPartNumber(item.part?.part_number || "");
  }, [item.part?.part_brand_id, item.part?.part_number]);

  const buildPartUpdateData = useCallback(
    (brandId: number | null, partNum: string | null): Part => ({
      part_brand_id: brandId,
      brand_id: car!.brand_id,
      car_model_id: car?.carModel?.id || null,
      car_version_id: car?.carVersion?.id || null,
      repair_subitem_id: item.budgetItem.repairSubitem.id,
      part_number: partNum,
    }),
    [car, item.budgetItem.repairSubitem.id]
  );

  const updatePurchaseItem = useCallback(
    async (updatedItem: WorkshopPurchaseItem) => {
      if (!selectedPurchase) return;
      const updatedItems = selectedPurchase.workshopPurchaseItems.map(
        (currentItem) =>
          currentItem.id === updatedItem.id ? updatedItem : currentItem
      );
      return updatePurchase(selectedPurchase.id, {
        workshopPurchaseItems: updatedItems,
      });
    },
    [selectedPurchase, updatePurchase]
  );

  const updatePartData = useCallback(
    async (partData: Part, updateType: string) => {
      if (!selectedPurchase) {
        enqueueSnackbar("Error: No se encontró la compra seleccionada.", {
          variant: "error",
        });
        return;
      }
      try {
        const updatedPart = await workshopPurchaseService.updatePart(partData);
        const partId = updatedPart.id;
        await workshopPurchaseItemService.update(item.id, { part_id: partId });

        const updatedItem: WorkshopPurchaseItem = {
          ...item,
          part: {
            ...item.part!,
            part_brand_id: partData.part_brand_id,
            part_number: partData.part_number,
          },
        };

        await updatePurchaseItem(updatedItem);

        const message =
          updateType === "partNumber"
            ? "Número de pieza actualizado con éxito"
            : "Marca actualizada con éxito";
        enqueueSnackbar(message, { variant: "success" });
      } catch (error) {
        enqueueSnackbar("Error al actualizar el número de pieza", {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, item, selectedPurchase, updatePurchaseItem]
  );

  const handleIconClick = useCallback(() => {
    setIsPartNumberVisible((prev) => !prev);
  }, []);

  const handlePartNumberBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value;
      setPartNumber(value);
      setIsPartNumberVisible(false);
      const partData = buildPartUpdateData(selectedBrandId || null, value);

      void updatePartData(partData, "partNumber");
    },
    [buildPartUpdateData, selectedBrandId, updatePartData]
  );

  const handleBrandChange = useCallback(
    async (selectedId: number) => {
      setSelectedBrandId(selectedId);
      const existingPartNumber = await workshopPurchaseService
        .getPart({
          part_brand_id: { eq: selectedId },
          brand_id: { eq: car?.brand_id },
          car_model_id: { in: [car?.carModel?.id || null] },
          car_version_id: { in: [car?.carVersion?.id || null] },
          repair_subitem_id: { eq: item.budgetItem.repairSubitem.id },
        })
        .then((response) => {
          if (response.success && response.data.length > 0) {
            return response.data[0].part_number || "";
          }
          return "";
        })
        .catch(() => "");
      setPartNumber(existingPartNumber || "");
      const partData = buildPartUpdateData(
        selectedId,
        existingPartNumber || null
      );
      void updatePartData(partData, "brand");
    },
    [buildPartUpdateData, car, item.budgetItem.repairSubitem.id, updatePartData]
  );

  const handleBrandChangeOrUnlink = useCallback(
    (newOption: PartBrand | null) => {
      if (newOption === null) {
        workshopPurchaseItemService
          .update(item.id, { part_id: null })
          .then(() => {
            enqueueSnackbar("Marca desvinculada", {
              variant: "warning",
            });
            setSelectedBrandId(undefined);
            const updatedItem: WorkshopPurchaseItem = {
              ...item,
              part: undefined,
            };
            void updatePurchaseItem(updatedItem);
          })
          .catch((error) => {
            enqueueSnackbar("Error al desvincular la marca", {
              variant: "error",
            }),
              console.error("Error al desvincular la marca: ", error);
          });
        return;
      }

      void handleBrandChange(newOption.id);
    },
    [enqueueSnackbar, handleBrandChange, item, updatePurchaseItem]
  );

  const handleOpen = useCallback(() => {
    setIsAutocompleteOpen(true);
    setInputValue("");
    void refetchPartBrands();
  }, [refetchPartBrands]);

  const handleClose = useCallback(() => {
    setIsAutocompleteOpen(false);
    if (!inputValue) {
      setInputValue(
        partBrands.find((brand) => brand.id === selectedBrandId)?.name || ""
      );
    }
  }, [inputValue, partBrands, selectedBrandId]);

  const copyToClipboard = useCallback(
    (text: string) => {
      navigator.clipboard.writeText(text).then(
        () => {
          enqueueSnackbar(`N° de pieza copiado: ${text} `, {
            variant: "success",
          });
        },
        (err) => {
          enqueueSnackbar(`Error al copiar el N° de pieza`, {
            variant: "error",
          });
          console.error("Error al copiar el N° de pieza: ", err);
        }
      );
    },
    [enqueueSnackbar]
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {isPartNumberVisible ? (
          <Input
            fullWidth
            autoFocus
            id={`part-number-${item.id}`}
            defaultValue={partNumber || ""}
            onBlur={handlePartNumberBlur}
            sx={{ fontSize: ".8rem", fontWeight: "450" }}
          />
        ) : (
          <FormControl fullWidth size="small">
            <InputLabel
              id={`select-brand-label-${item.id}`}
              shrink={!!selectedBrandId || !!partNumber}
            >
              {selectedBrandId && partNumber && `Nº ${partNumber}`}
            </InputLabel>

            <AutocompleteFilter
              label={
                selectedBrandId && partNumber
                  ? `Nº ${partNumber}`
                  : "Marca de pieza"
              }
              textFieldVariant="outlined"
              textFieldsize="small"
              loading={isLoadingPartBrand || isFetchingNextPage}
              options={partBrands}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={
                isAutocompleteOpen
                  ? null
                  : partBrands.find((brand) => brand.id === selectedBrandId) ??
                    null
              }
              onChangeValue={handleBrandChangeOrUnlink}
              fetchNextPage={() => {
                fetchNextPage().catch((error) =>
                  console.error("Error en fetchNextPage:", error)
                );
              }}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              onInputChange={(_event, value) => {
                setInputValue(value);
              }}
              onOpen={handleOpen}
              onClose={handleClose}
              disable={
                isInputDisabled ||
                item.budgetItem.repairSubitem.repair_subitem_type_id !==
                  RepairSubitem.REPLACEMENT_TYPE_ID
              }
            />
          </FormControl>
        )}
        <>
          {!isInputDisabled &&
            selectedBrandId &&
            user.permissions.includes(
              PermissionEnum.PART_UPDATE_PART_NUMBER
            ) && (
              <Box sx={{ display: "flex" }}>
                <Tooltip
                  title={partNumber ? "Editar N° de pieza" : "N° de pieza"}
                  arrow
                  placement="top"
                >
                  <IconButton
                    color="primary"
                    aria-label="add to shopping cart"
                    onClick={handleIconClick}
                  >
                    {partNumber ? (
                      <ModeEditIcon fontSize="small" />
                    ) : (
                      <AddBoxIcon fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          {partNumber && (
            <Tooltip title="Copiar N° de pieza" arrow placement="top">
              <IconButton
                color="primary"
                onClick={() => copyToClipboard(partNumber)}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </>
      </Box>
    </>
  );
}
