import { FormControl, InputLabel, OutlinedInput, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

interface PriceInput {
  id: number;
  price: number | undefined;
  lastPrice: number | null;
  disabled?: boolean;
  onBlur?: () => void;
}

export default function PriceInput({
  id,
  price,
  lastPrice,
  onBlur,
  disabled = false,
}: PriceInput) {
  const [inputValue, setInputValue] = useState<string | number>(price ?? "");

  const mustUseLastPrice = () => !price && lastPrice;

  const styles = {
    PRICE_LATEST_QUOTE: {
      color: "rgba(0, 0, 0, 0.5)",
    },
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    const formatNumber = (value: number | undefined | null) => {
      if (value === null || value === undefined) {
        return "";
      }
      return new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
      }).format(value);
    };

    const formattedValue = formatNumber(price ?? lastPrice ?? 0);
    setInputValue(formattedValue);
  }, [price, lastPrice]);

  const input = () => (
    <Tooltip
      title={
        <>
          {mustUseLastPrice() ? "Última cotización: " : ""}
          {inputValue}
        </>
      }
      placement="top"
      arrow
    >
      <FormControl
        size="small"
        sx={{ minWidth: "90px", color: "blue" }}
        variant="outlined"
      >
        <InputLabel
          sx={{
            color: "#ff5722",
          }}
        >
          {mustUseLastPrice() ? "Última cotización" : ""}
        </InputLabel>
        <OutlinedInput
          id={`item-price-${id}`}
          label={mustUseLastPrice() ? "Última cotización" : ""}
          disabled={disabled}
          onBlur={onBlur}
          onChange={handleInputChange}
          value={inputValue}
          inputProps={{
            style: mustUseLastPrice() ? styles.PRICE_LATEST_QUOTE : {},
            sx: {
              fontSize: ".8rem",
              fontWeight: "450",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: mustUseLastPrice() ? "#ff5722" : undefined,
            },
          }}
        />
      </FormControl>
    </Tooltip>
  );
  return input();
}
