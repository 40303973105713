import { TypeKanbanBudgetGroup } from "./TypeKanbanBudget";
import {
  Box,
  Typography,
  Avatar,
  Tooltip,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@mui/material";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StateProgressBar from "../../../Purchase/StateProgressBar";
import { WorkshopBudgetState } from "../../../../entities/workshop-budget-state";
import {
  ArrivalDateIcon,
  ExitDateIcon,
  UndatedIcon,
  TurnIcon,
} from "./DateIcon";
import { useDarkMode } from "../../../ThemeContext";
import React, { useContext } from "react";
import { PurchaseContext } from "../../Purchase/context/PurchaseContext";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { parseUTCDate } from "../../../../utils/dateUtils";
import { Car } from "../../../../entities/car.entity";

interface TaskProps {
  task: TypeKanbanBudgetGroup;
}

interface BudgetStatusProps {
  status: number;
  appointment: Date | null;
  workshop_arrival_date: Date | null;
  workshop_exit_date: Date | null;
}

const arrivalDate = [
  WorkshopBudgetState.ADMITTED_ID,
  WorkshopBudgetState.DIAGNOSIS_ID,
  WorkshopBudgetState.PURCHASE_REQUESTED_ID,
  WorkshopBudgetState.REPAIR_ID,
  WorkshopBudgetState.FINISHED_ID,
];
const appointmentDate = [WorkshopBudgetState.APPOINTMENT_ID];
const exitDate = [WorkshopBudgetState.DELIVERED_ID];

const BudgetStatus = ({
  status,
  appointment,
  workshop_arrival_date,
  workshop_exit_date,
}: BudgetStatusProps) => {
  switch (true) {
    case appointmentDate.includes(status):
      return <TurnIcon appointment={appointment} />;
    case arrivalDate.includes(status):
      return (
        <ArrivalDateIcon
          workshop_arrival_date={workshop_arrival_date}
          workshop_exit_date={workshop_exit_date}
        />
      );
    case exitDate.includes(status):
      return <ExitDateIcon workshop_exit_date={workshop_exit_date} />;
    default:
      return <UndatedIcon undated="Sin turno" />;
  }
};

const BudgetKanbanItem = React.memo(({ task }: TaskProps) => {
  const { isDarkMode } = useDarkMode();
  const { budgets } = task;

  const firstBudgetItem = budgets[0];
  const budget = budgets[0].originalBudget;

  const {
    lastModificationHoverDate,
    lastModificationFormattedDate,
    lastModificationBg,
    lastModificationColor,
  } = firstBudgetItem;

  const { purchases } = useContext(PurchaseContext);
  const hasPurchase = budgets.some(
    (b) => b.originalBudget.workshop_purchase_id
  );
  const showAccordion = budgets.length > 2;

  const budgetArrivalDate = parseUTCDate(budget.workshop_arrival_date);
  const budgetExitDate = parseUTCDate(budget.workshop_exit_date);

  const combinedTags = budgets.reduce<
    Array<{
      bg: string;
      text: string;
      title: string;
      PurchaseState?: string;
      budgetId: number;
    }>
  >((acc, curr) => [...acc, ...curr.tags], []);

  const style = {
    paddingX: "5px",
    paddingY: "2px",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "4px",
    whiteSpace: "nowrap",
    margin: "5px 0px",
  };

  return (
    <Box
      sx={{
        width: "100%",
        cursor: "pointer!important",
        backgroundColor: isDarkMode ? "#2E363E" : "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 1,
        alignItems: "start",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
        paddingX: 1.5,
        paddingY: 1.5,
        minWidth: "350px",
        minHeight: "170px",
        ":hover": {
          background: isDarkMode
            ? "#141A21"
            : "var(--ds-background-neutral-subtle-hovered,#F4F5F7)",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "start",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "15.5px",
                fontWeight: "medium",
                marginRight: "10px",
              }}
            >
              {Car.getSummary(budget.car)}
            </Typography>
          </Box>

          {budget.in_workshop ? (
            <Tooltip arrow title="Vehículo en taller">
              <CarRepairIcon color="success" />
            </Tooltip>
          ) : (
            <Tooltip arrow title="Vehículo fuera de taller">
              <CarRepairIcon color="disabled" />
            </Tooltip>
          )}
        </Box>

        <Typography
          variant="body2"
          sx={{
            fontSize: "11px",
            color: "gray.500",
          }}
        >
          {budget.company.name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <BudgetStatus
            status={budget.workshop_budget_state_id}
            appointment={budget.appointment_date}
            workshop_arrival_date={budgetArrivalDate}
            workshop_exit_date={budgetExitDate}
          />
          <Tooltip
            arrow
            title={`Ultimo cambio de estado ${lastModificationHoverDate}`}
          >
            <Box
              sx={{
                background: lastModificationBg,
                color: lastModificationColor,
                borderRadius: "4px",
                padding: "2px 5px 2px 5px",
                minHeight: "24px",
                textAlign: "center",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                {lastModificationFormattedDate}
              </Typography>
            </Box>
          </Tooltip>

          {hasPurchase ? (
            <Box
              sx={{
                borderRadius: "4px",
                background: "#2e7d32",
                padding: "2px 5px 2px 5px",
                display: "flex",
                minHeight: "24px",
                alignItems: "center",
              }}
            >
              <Tooltip arrow title="Solicitud de compra">
                <ShoppingCartIcon sx={{ color: "white", fontSize: "1rem" }} />
              </Tooltip>
            </Box>
          ) : (
            <Box
              sx={{
                borderRadius: "4px",
                background: "rgba(0, 0, 0, 0.26)",
                padding: "2px 5px 2px 5px",
                display: "flex",
                minHeight: "24px",
                alignItems: "center",
              }}
            >
              <Tooltip arrow title="Sin solicitud de compra">
                <ShoppingCartIcon sx={{ color: "white", fontSize: "1rem" }} />
              </Tooltip>
            </Box>
          )}
        </Box>

        <Box>
          {budget.createdBy.name ? (
            <Avatar sx={{ width: 24, height: 24 }}>
              <Typography sx={{ fontSize: "0.7rem" }}>
                {budget.createdBy.name.substring(0, 1)}
                {budget.createdBy.lastname.substring(0, 1)}
              </Typography>
            </Avatar>
          ) : (
            <Avatar sx={{ width: 24, height: 24 }}></Avatar>
          )}
        </Box>
      </Box>

      <Tooltip arrow title="Presupuestos">
        {showAccordion ? (
          <Accordion
            sx={{
              width: "100%",
              border: "none",
              boxShadow: "none",
              margin: "0xp 5px",

              ":hover": {
                background: "rgba(214, 20, 50, 0.03)",
              },
            }}
            onClick={(event) => event.stopPropagation()}
          >
            <AccordionSummary
              sx={{ padding: "0px 0px 0px 16px" }}
              expandIcon={<ArrowDropDownIcon />}
            >
              <Box
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  width: "100%",
                }}
              >
                {budgets.slice(0, 2).map((budget) => {
                  const budgetPurchases = purchases.filter(
                    (purchase) =>
                      purchase.budget.id === Number(budget.originalBudget.id)
                  );

                  const budgetTag = budget.tags.find(
                    (tag) => tag.budgetId === Number(budget.originalBudget.id)
                  );

                  return (
                    <Box
                      key={budget.originalBudget.id}
                      sx={{ marginBottom: "10px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        {budgetTag ? (
                          <Typography
                            sx={style}
                            style={{
                              backgroundColor: budgetTag.bg,
                              color: budgetTag.text,
                            }}
                          >
                            {budgetTag.title}
                          </Typography>
                        ) : null}

                        {budgetPurchases.length > 0 &&
                          budgetTag &&
                          budgetTag.PurchaseState && (
                            <Tooltip
                              arrow
                              title={"Estado de solicitud de compra"}
                            >
                              <Typography
                                sx={style}
                                style={{
                                  backgroundColor: "#2e7d32",
                                  color: "#ffffff",
                                }}
                              >
                                {budgetTag.PurchaseState}
                              </Typography>
                            </Tooltip>
                          )}
                      </Box>

                      {budgetPurchases.map((p) => (
                        <StateProgressBar
                          key={p.id}
                          states={p.workshopPurchaseItems.map(
                            (item) => item.workshopPurchaseItemState.name
                          )}
                        />
                      ))}
                    </Box>
                  );
                })}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px 24px 0px 16px" }}>
              {budgets.slice(2).map((budget) => {
                const budgetPurchases = purchases.filter(
                  (purchase) =>
                    purchase.budget.id === Number(budget.originalBudget.id)
                );

                const budgetTag = budget.tags.find(
                  (tag) => tag.budgetId === Number(budget.originalBudget.id)
                );

                return (
                  <Box
                    key={budget.originalBudget.id}
                    sx={{ marginBottom: "10px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      {budgetTag ? (
                        <Typography
                          sx={style}
                          style={{
                            backgroundColor: budgetTag.bg,
                            color: budgetTag.text,
                          }}
                        >
                          {budgetTag.title}
                        </Typography>
                      ) : null}

                      {budgetPurchases.length > 0 &&
                        budgetTag?.PurchaseState && (
                          <Tooltip
                            arrow
                            title={"Estado de solicitud de compra"}
                          >
                            <Typography
                              sx={style}
                              style={{
                                backgroundColor: "#2e7d32",
                                color: "#ffffff",
                              }}
                            >
                              {budgetTag.PurchaseState}
                            </Typography>
                          </Tooltip>
                        )}
                    </Box>

                    {budgetPurchases.map((p) => (
                      <StateProgressBar
                        key={p.id}
                        states={p.workshopPurchaseItems.map(
                          (item) => item.workshopPurchaseItemState.name
                        )}
                      />
                    ))}
                  </Box>
                );
              })}
            </AccordionDetails>
          </Accordion>
        ) : (
          <>
            <Box sx={{ width: "100%" }}>
              {combinedTags.map((tag, id) => (
                <Box
                  key={id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={style}
                    style={{ backgroundColor: tag.bg, color: tag.text }}
                  >
                    {tag.title}
                  </Typography>
                  {hasPurchase && (
                    <Tooltip arrow title={"Estado de solicitud de compra"}>
                      <Typography
                        sx={{
                          paddingX: "10px",
                          paddingY: "2px",
                          fontSize: "12px",
                          fontWeight: "medium",
                          borderRadius: "4px",
                          whiteSpace: "nowrap",
                          margin: "5px",
                        }}
                        style={{
                          backgroundColor: "#2e7d32",
                          color: "#ffffff",
                        }}
                      >
                        {tag.PurchaseState}
                      </Typography>
                    </Tooltip>
                  )}
                </Box>
              ))}
            </Box>
          </>
        )}
      </Tooltip>
    </Box>
  );
});

export default BudgetKanbanItem;
