import { DataGrid, GridColDef, GridRowClassNameParams } from "@mui/x-data-grid";
import "../Purchase/Index.css";
import TimerCell from "./TimerCell";
import { useCallback, useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import "../../../index.css";
import { useDarkMode } from "../../ThemeContext";
import { UserWorkService } from "../../../services/UserWorkService";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import { UserWorkTask } from "../../../entities/user-work-task.entity";
import { UserWorkBudget } from "../../../entities/user-work-budget.entity";

interface RowData {
  id: number;
  car: string;
  totalTime: string | null;
  brand: string;
  model: string;
  startDate: string;
}

export default function MechanicsJobList() {
  const [rows, setRows] = useState<RowData[]>([]);
  const [activeTimers, setActiveTimers] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [pendingJob, setPendingJob] = useState<number | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [comment, setComment] = useState(
    localStorage.getItem("pendingComment") || ""
  );
  const [pendingJobDetails, setPendingJobDetails] = useState<RowData | null>(
    null
  );
  const [startDate, setStartDate] = useState<string>("");
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const [resetTimerSignal, setResetTimerSignal] = useState<{
    budgetId: number;
    signal: number;
  } | null>(null);
  const { isDarkMode } = useDarkMode();
  const userWorkService = new UserWorkService();

  const minDate = pendingJobDetails?.startDate || "";
  const maxDate = new Date().toISOString().split(".")[0];

  useEffect(() => {
    const fetchAssignedBudgets = async () => {
      try {
        const data = await userWorkService.getAssignedBudgets();
        const formattedRows = data.map((budget: UserWorkBudget) => ({
          id: budget.budget_id,
          car: budget.identification,
          totalTime: budget.total_time,
          brand: budget.brand,
          model: budget.car_model,
          startDate: budget.start_date,
          timer: "",
        }));

        setRows(formattedRows);

        if (pendingJob) {
          const jobDetails = formattedRows.find((row) => row.id === pendingJob);
          setPendingJobDetails(jobDetails || null);

          if (jobDetails) {
            setStartDate(jobDetails.startDate);
          }
        }
      } catch (error) {
        console.error("Error fetching assigned budgets:", error);
        enqueueSnackbar("Error al cargar los presupuestos asignados", {
          variant: "error",
        });
      }
    };

    const fetchPendingJob = async () => {
      try {
        const response = await userWorkService.PendingJob();
        const pendingData = response.budget_id;

        if (pendingData) {
          setPendingJob(pendingData);
          setOpenDialog(true);
        }
      } catch (error) {
        console.error("Error fetching pending job:", error);
        enqueueSnackbar("Error al verificar trabajos pendientes", {
          variant: "error",
        });
      }
    };

    fetchAssignedBudgets();
    fetchPendingJob();
  }, [pendingJob]);

  const handleUpdateEndDate = async () => {
    if (!pendingJob || !endDate) {
      enqueueSnackbar("Debe ingresar una fecha de fin válida.", {
        variant: "warning",
      });
      return;
    }

    if (!selectedTaskId) {
      enqueueSnackbar("Debe seleccionar un tipo de trabajo.", {
        variant: "warning",
      });
      return;
    }

    try {
      const isoEndDate = new Date(endDate).toISOString();
      await userWorkService.updateEndDate(
        pendingJob,
        isoEndDate,
        selectedTaskId,
        comment
      );

      enqueueSnackbar("Trabajo actualizado correctamente.", {
        variant: "success",
      });

      localStorage.removeItem(`timer-start-${pendingJobDetails?.id}`);
      localStorage.removeItem("pendingComment");

      setOpenDialog(false);
      setPendingJob(null);
      setEndDate("");
      setComment("");
      setActiveTimers({});
      setSelectedTaskId(null);
      setResetTimerSignal({
        budgetId: pendingJobDetails!.id,
        signal: Date.now(),
      });
    } catch (error) {
      console.error("Error updating job end date:", error);
      enqueueSnackbar("Error al actualizar la fecha de fin del trabajo.", {
        variant: "error",
      });
    }
  };

  const handleStatusChange = useCallback(
    (budgetId: number, isActive: boolean) => {
      if (pendingJob) {
        enqueueSnackbar(
          "Debe completar el trabajo pendiente antes de iniciar uno nuevo.",
          {
            variant: "info",
          }
        );
        return;
      }

      setActiveTimers((prev) => {
        if (prev[budgetId] === isActive) {
          return prev;
        }
        return { ...prev, [budgetId]: isActive };
      });
    },
    [pendingJob]
  );

  const handleDialogClose = () => {
    enqueueSnackbar("Debe completar la información pendiente.", {
      variant: "warning",
    });
    setOpenDialog(true);
  };

  const columns: GridColDef<RowData>[] = [
    { field: "id", headerName: "Presupuesto", sortable: false, flex: 1 },
    { field: "car", headerName: "Unidad", flex: 1 },
    { field: "brand", headerName: "Marca", flex: 1 },
    { field: "model", headerName: "Modelo", flex: 1 },
    {
      field: "totalTime",
      headerName: "Tiempo total acomulado",
      flex: 1,
    },
    {
      field: "timer",
      headerName: "Progreso",
      flex: 1,
      renderCell: (params) => (
        <TimerCell
          budgetId={params.row.id}
          onStatusChange={handleStatusChange}
          selectedTaskId={selectedTaskId}
          setSelectedTaskId={setSelectedTaskId}
          handleTaskChange={handleTaskChange}
          resetTimerSignal={resetTimerSignal}
        />
      ),
    },
  ];

  const getRowClassName = (params: GridRowClassNameParams<RowData>) => {
    const rowActive = activeTimers[params.row.id];

    switch (true) {
      case rowActive && isDarkMode:
        return "row-active-darkMode";
      case rowActive && !isDarkMode:
        return "row-active";
      default:
        return "";
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(e.target.value).toISOString();

    if (selectedDate < minDate || selectedDate > maxDate) {
      enqueueSnackbar("La fecha debe estar dentro del rango permitido.", {
        variant: "warning",
      });

      return;
    }

    setEndDate(e.target.value);
  };

  const handleTaskChange = (event: SelectChangeEvent<number | null>) => {
    setSelectedTaskId(event.target.value ? Number(event.target.value) : null);
  };

  return (
    <>
      <h2>Trabajos asignados</h2>

      <DataGrid
        slotProps={{ row: { style: { cursor: "pointer" } } }}
        rows={rows}
        columns={columns}
        getRowClassName={getRowClassName}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20]}
      />
      {pendingJobDetails && (
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Trabajo pendiente</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Hay un trabajo pendiente del día anterior. Por favor, indique la
              hora de finalización:
            </DialogContentText>

            <TableContainer
              component={Paper}
              sx={{ margin: "10px 0px 10px 0px" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Unidad</TableCell>
                    <TableCell>Marca</TableCell>
                    <TableCell>Modelo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{pendingJobDetails.id}</TableCell>
                    <TableCell>{pendingJobDetails.car}</TableCell>
                    <TableCell>{pendingJobDetails.brand}</TableCell>
                    <TableCell>{pendingJobDetails.model}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Tooltip
              title={`La fecha no puede superar el día actual y no debe ser menor al día del inicio: ${format(
                minDate,
                "dd/MM/yyyy hh:mm:ss"
              )}`}
              arrow
            >
              <TextField
                sx={{ input: { cursor: "pointer" } }}
                autoFocus
                margin="dense"
                label="Hora de finalización"
                type="datetime-local"
                fullWidth
                variant="outlined"
                value={endDate}
                onChange={handleDateChange}
                inputProps={{
                  min: startDate,
                  max: new Date().toISOString().split(".")[0],
                }}
              />
            </Tooltip>
            <FormControl fullWidth margin="dense">
              <InputLabel id="task-select-label">Tipo de Trabajo</InputLabel>
              <Select
                labelId="task-select-label"
                value={selectedTaskId}
                onChange={handleTaskChange}
                label="Tipo de Trabajo"
              >
                {UserWorkTask.getTaskOptions().map((task) => (
                  <MenuItem key={task.id} value={task.id}>
                    {task.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              label="Comentario"
              type="text"
              fullWidth
              variant="outlined"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
                localStorage.setItem("pendingComment", e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleUpdateEndDate} color="primary">
              Actualizar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
