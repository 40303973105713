/* eslint-disable @typescript-eslint/no-unused-vars */
export abstract class BaseApplicationService<Entity, P> {
  findOneById(_id: number): Promise<Entity | undefined> {
    throw new Error("Method not implemented.");
  }

  findOne(_params: P): Promise<Entity> {
    throw new Error("Method not implemented.");
  }
  find(_params?: P): Promise<Entity[] | { data: Entity[]; totalCount: number }> {
    throw new Error("Method not implemented.");
  }
}
