import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { UserWorkTask } from "../../../entities/user-work-task.entity";

interface StopTimerDialogProps {
  open: boolean;
  onClose: () => void;
  comment: string;
  setComment: (comment: string) => void;
  selectedTaskId: number | null;
  handleTaskChange: (
    event: SelectChangeEvent<any>,
    child: React.ReactNode
  ) => void;
  onSendComment: () => void;
  onNoComment: () => void;
}

const StopTimerDialog: React.FC<StopTimerDialogProps> = ({
  open,
  onClose,
  comment,
  setComment,
  selectedTaskId,
  handleTaskChange,
  onSendComment,
  onNoComment,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Detener Tarea</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Puedes dejar un comentario antes de detener la tarea.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Comentario"
          type="text"
          fullWidth
          variant="outlined"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="task-select-label">Tipo de Trabajo</InputLabel>
          <Select
            labelId="task-select-label"
            value={selectedTaskId}
            onChange={handleTaskChange}
            label="Tipo de Trabajo"
          >
            {UserWorkTask.getTaskOptions().map((task) => (
              <MenuItem key={task.id} value={task.id}>
                {task.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNoComment} color="primary" disabled={!!comment}>
          Salir y detener
        </Button>
        <Button
          onClick={onSendComment}
          color="primary"
          disabled={!comment.trim()}
        >
          Detener
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StopTimerDialog;
