import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Modal,
  Box,
} from "@mui/material";

import { Comment } from "../../entities/comment.entity";
import { CommentService } from "../../services/CommentService";
import { formatUTCDate } from "../../utils/dateUtils";

interface CommentBoxProps {
  comment: Comment;
}

/** TODO: USE NORMAL WAY FOR DECLARE COMPONENT */
const CommentBox: React.FC<CommentBoxProps> = React.memo(({ comment }) => {
  const [focusedImage, setFocusedImage] = useState<string | null>(null);
  const [thumbURLList, setThumbURLList] = useState<string[]>([]);

  const handleImageClick = (idx: number) => {
    const attachment = comment.commentAttachments[idx];
    CommentService.getAttachment(attachment.comment_id, idx).then(
      (file: Blob) => {
        if (file.type.includes("image")) {
          setFocusedImage(URL.createObjectURL(file));
        }
      }
    );
  };

  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  useEffect(() => {
    comment.commentAttachments.forEach((a, idx: number) => {
      CommentService.getAttachment(a.comment_id, idx).then((file: Blob) => {
        if (file.type.includes("image")) {
          setThumbURLList((prevVal) => {
            return [...prevVal, URL.createObjectURL(file)];
          });
        }
      });
    });
  }, []);

  useEffect(() => {
    const focusedCommentId = window.location.hash.substring(1);
    const focusedCommentSection = document.getElementById(focusedCommentId);

    if (focusedCommentSection) {
      focusedCommentSection.scrollIntoView({ behavior: "smooth" });
      focusedCommentSection.style.boxShadow = "2px 2px 2px 2px blue";
      setTimeout(() => {
        focusedCommentSection.style.boxShadow =
          "1px 2px 1px 1px rgba(0,0,0,0.2)";
      }, 5000);
    }
  }, []);

  return (
    <Card style={{ marginBottom: "10px" }} id={"comment-" + comment.id}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CardHeader
          avatar={<Avatar>{comment.createdBy.name.split("")[0]}</Avatar>}
          title={`${comment.createdBy.fullname}`}
        />
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ alignContent: "center", padding: "16px" }}
        >
          {formatUTCDate(comment.created_at.toString())}
        </Typography>
      </Box>
      <CardContent>
        <Typography>{comment.body}</Typography>
        {thumbURLList.map((url, idx) => (
          <img
            key={idx}
            src={url}
            style={{
              maxWidth: "50px",
              margin: "10px",
              cursor: "pointer",
            }}
            onClick={() => handleImageClick(idx)}
          />
        ))}
        <Modal
          open={Boolean(focusedImage)}
          onClose={() => setFocusedImage(null)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <Box sx={style}>
              <img
                src={focusedImage || ""}
                alt="Imagen Enfocada"
                style={{ maxHeight: 500 }}
              />
            </Box>
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
});

export default CommentBox;
