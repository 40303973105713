import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  HttpStatusCode,
  InternalAxiosRequestConfig,
} from "axios";

import { getToken, removeToken } from "./AuthService";

const baseURL = import.meta.env.VITE_API_HOST as string | undefined;

if (!baseURL) {
  throw new Error(
    "VITE_API_HOST no está definido en las variables de entorno."
  );
}

const ApiService: AxiosInstance = axios.create({
  baseURL,
});

ApiService.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (config.headers && "set" in config.headers) {
      config.headers.set("Authorization", `Bearer ${getToken() || ""}`);
    }

    return config;
  }
);
ApiService.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => response,
  (error: AxiosError): Promise<never> => {
    if (error.code === AxiosError.ERR_NETWORK) {
      alert("No se pudo conectar con el servidor.");
      return Promise.reject(error);
    }

    if (error.response?.status === HttpStatusCode.Unauthorized) {
      removeToken();
      location.href = "/";
    }
    if (error.response?.status === HttpStatusCode.Forbidden) {
      alert("No tienes permiso a este recurso.");
    }
    return Promise.reject(error);
  }
);

export async function fetchPaginatedData<T>(
  endpoint: string,
  page = 1,

  filters?: Record<string, unknown>
): Promise<{ data: T[]; totalCount: number } | Blob> {
  try {
    const response = await ApiService.get(endpoint, {
      params: {
        page,
        ...filters,
      },
    });

    const data = response?.data.data.map((item: T) => item) || [];
    const totalCount = parseInt(
      response.headers["x-pagination-total-count"],
      10
    );
    return { data, totalCount };
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function fetchAllPages<T>(
  endpoint: string,
  pageSize = 20,
  filters?: any,
  method: "GET" | "POST" = "GET",
  body?: any
): Promise<T[]> {
  try {
    let allData: T[] = [];
    let page = 1;
    let totalPages = 1;

    do {
      const queryParams = {
        page,
        pageSize,
        ...filters,
      };

      let response;

      if (method == "GET") {
        response = await ApiService.get(endpoint, {
          params: queryParams,
        });
      } else if (method === "POST") {
        response = await ApiService.post(endpoint, body, {
          params: queryParams,
        });
      }

      if (response?.data.success) {
        const { data } = response?.data;
        allData = [...allData, ...data];

        const headers = response.headers;
        pageSize = parseInt(headers["x-pagination-per-page"], 10) || pageSize;
        totalPages =
          parseInt(response.headers["x-pagination-page-count"], 10) ||
          totalPages;
        page++;
      } else {
        throw new Error(`Error fetching data from ${endpoint}`);
      }
    } while (page <= totalPages);

    return allData;
  } catch (error) {
    console.error(`Error fetching all pages from ${endpoint}:`, error);
    throw error;
  }
}

export default ApiService;
