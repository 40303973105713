import { useContext, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { Tooltip, Typography } from "@mui/material";
import "../Purchase/Index.css";

import PushPinIcon from "@mui/icons-material/PushPin";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { Budget } from "../../../entities/budget.entity";
import { BudgetState } from "../../../entities/budget-state.entity";
import { Car } from "../../../entities/car.entity";
import { BudgetContext } from "./contexBudget/BudgetContext";
import BudgetModal from "./BudgetModal";
import { formatUTCDate } from "../../../utils/dateUtils";
import { parse, format } from "date-fns";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import useDebounce from "../../../hooks/useDebounce";

export default function BudgetList() {
  const {
    pagedBudgets,
    loading,
    setOpen,
    setSelectedBudgetData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    setFilters,
    totalCount,
    pageSize,
  } = useContext(BudgetContext);

  const [anchoredRows, setAnchoredRows] = useState<number[]>([]);
  const [page, setPage] = useState<number>(0);
  const [localFilters, setLocalFilters] = useState<any>({});

  const debouncedFilters = useDebounce(localFilters, 700);

  useEffect(() => {
    setFilters(debouncedFilters);
  }, [debouncedFilters, setFilters]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    if (
      newPage * pageSize >= pagedBudgets.length &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.6 },
    {
      field: "carDetails",
      headerName: "Vehículo",
      flex: 1,
      valueGetter: (params: GridRenderCellParams<Budget>) => {
        return Car.getSummary(params.row.car);
      },
    },
    {
      field: "budgetState",
      headerName: "Estado",
      flex: 1.6,
      valueGetter: (params) => {
        const state = params.row.budgetState.name;
        let color = "";
        let background = "";

        switch (state) {
          case BudgetState.QUOTE_AT_WORKSHOP_LABEL:
          case BudgetState.RECOSTE_AT_WORKSHOP_LABEL:
            color = "#d97706"; //amarillo
            background = "#fef3c7";
            break;
          case BudgetState.FINISHED_LABEL:
            color = "#2563eb"; //azul
            background = "#dbeafe";
            break;
          case BudgetState.REJECTED_BY_CLIENT_LABEL:
          case BudgetState.CANCELLED_BY_LAX_LABEL:
            color = "#FF0000"; //rojo
            background = "#FAD4D4";
            break;

          case BudgetState.APPROVED_BY_CLIENT_LABEL:
            color = "#059669"; //verde
            background = "#d1fae5";
            break;
          case BudgetState.EXPIRED_LABEL:
            color = "#6b7280"; //gris
            background = "#d1d5db";
            break;
          case BudgetState.BUDGETED_LABEL:
          case BudgetState.ADDITIONALS_QUOTED_LABEL:
            color = "#5AB2FF"; //celeste
            background = "#CAF4FF";
            break;
          case BudgetState.LAX_VERIFICATION_LABEL:
          case BudgetState.QUOTE_ADDITIONAL_LABEL:
            color = "#F97300"; //Naranja
            background = "#FFCF81";
            break;
          default:
            color = "";
        }
        return { state, color, background };
      },
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.value.color,
            background: params.value.background,
            borderRadius: "5px",
            padding: "2px 3.5px 2px 3.5px",
          }}
        >
          {params.value.state}
        </Typography>
      ),
    },

    {
      field: "purchaseId",
      headerName: "Solicitud de compra",
      flex: 1,
      valueGetter: (_params: GridRenderCellParams<Budget>) => {
        // const purchase = [params.row.workshopPurchase];

        // return purchase && purchase.length > 0 ? (
        //   <Box>
        //     <ShoppingCartIcon color="success" />
        //   </Box>
        // ) : (
        //   <ShoppingCartIcon color="disabled" />
        // );

        return null;
      },
    },
    {
      field: "appointment_date",
      headerName: "Turno",
      flex: 1,
      // renderCell: (params: GridRenderCellParams<Budget>) => {
      //   const Turn = params.row.appointment_date;

      //   return Turn ? (
      //     <Box sx={{ display: "flex" }}>
      //       <CalendarMonthIcon color="success" />
      //       <Typography sx={{ marginLeft: "5px" }}>
      //         {format(new Date(), "dd/MM/yyyy")}
      //       </Typography>
      //     </Box>
      //   ) : (
      //     <Box sx={{ display: "flex" }}>
      //       <CalendarMonthIcon color="disabled" />
      //       <Typography sx={{ marginLeft: "5px" }}>
      //         {format(new Date(), "dd/MM/yyyy")}
      //       </Typography>
      //     </Box>
      //   );
      // },
      valueGetter: (params: GridRenderCellParams<Budget>) =>
        params.row.appointment_date &&
        formatUTCDate(params.row.appointment_date),
    },
    {
      field: "in_workshop",
      headerName: "En taller",
      flex: 0.6,
      renderCell: (params: GridRenderCellParams<Budget>) => (
        <>{params.row.in_workshop ? "Si" : "No"}</>
      ),
      valueGetter: (params: GridRenderCellParams<Budget>) => {
        return `${params.row.in_workshop}`;
      },
    },
    {
      field: "creation_date",
      headerName: "Creación del presupuesto",
      flex: 1,
      valueGetter: (params: GridRenderCellParams<Budget>) =>
        formatUTCDate(params.row.creation_date),
    },
    {
      field: "",
      headerName: "Anclado",
      flex: 0.6,
      renderCell: (params: GridRenderCellParams<Budget>) => (
        <Tooltip title="Anclar" arrow>
          <PushPinIcon
            fontSize="small"
            sx={{
              rotate: anchoredRows.includes(params.row.id) ? "55deg" : "0deg",
              color: anchoredRows.includes(params.row.id) ? "blue" : "grey",
            }}
            onClick={(event) => {
              event.stopPropagation();
              handleAnchorRow(params.row.id);
            }}
          ></PushPinIcon>
        </Tooltip>
      ),
    },
  ];

  const handleAnchorRow = (rowId: number) => {
    setAnchoredRows((prevAnchoredRows) => {
      if (prevAnchoredRows.includes(rowId)) {
        return prevAnchoredRows.filter((id) => id !== rowId);
      } else {
        return [rowId, ...prevAnchoredRows];
      }
    });
  };

  const handleFilterChange = (filterModel: GridFilterModel) => {
    const newFilters = filterModel.items.reduce((acc: any, filter: any) => {
      if (filter.field && filter.value != null && filter.value !== "") {
        let fieldName = filter.field;
        let fieldValue = filter.value;

        if (fieldName === "budgetState") {
          fieldName = "budget_state_id";
          const formattedValue = capitalizeFirstLetter(filter.value);
          fieldValue = BudgetState.getIdByName(formattedValue) || filter.value;
        } else if (fieldName === "carDetails") {
          fieldName = "car_identification";
          const upperCaseIdentification = filter.value.toUpperCase();
          fieldValue = upperCaseIdentification || filter.value;
        } else if (fieldName === "in_workshop") {
          const convertedValue = convertInWorkshop(filter.value);
          fieldValue = convertedValue !== null ? convertedValue : filter.value;
        } else if (fieldName === "creation_date") {
          fieldValue = parseDate(filter.value) || filter.value;
        } else if (fieldName === "appointment_date") {
          fieldValue = parseDate(filter.value) || filter.value;
        }
        acc[`BudgetSearch[${fieldName}]`] = fieldValue;
      }
      return acc;
    }, {});
    setLocalFilters(newFilters);
  };

  const convertInWorkshop = (value: string): number | null => {
    const lowerValue = value.toLowerCase();
    if (lowerValue === "si") {
      return 1;
    } else if (lowerValue === "no") {
      return 0;
    } else {
      return null;
    }
  };

  const parseDate = (dateString: string): string | null => {
    const formats = [
      "yyyy-MM-dd",
      "MM/dd/yyyy",
      "dd/MM/yyyy",
      "dd-MM-yyyy",
      "MMM d, yyyy",
      "MMMM d, yyyy",
    ];

    for (const fmt of formats) {
      const parsedDate = parse(dateString, fmt, new Date());
      if (!isNaN(parsedDate.getTime())) {
        return format(parsedDate, "yyyy-MM-dd");
      }
    }

    return null;
  };

  const createBudgetGroup = (budget: Budget) => {
    return {
      id: `single-${budget.id}`,
      budgets: [
        {
          originalBudget: budget,
          tags: [],
          lastModificationHoverDate: "",
          lastModificationFormattedDate: "",
          lastModificationBg: "",
          lastModificationColor: "",
        },
      ],
    };
  };

  return (
    <>
      <h2>Presupuestos</h2>
      <DataGrid
        loading={loading || isFetchingNextPage}
        rows={pagedBudgets.slice(page * pageSize, (page + 1) * pageSize)}
        columns={columns}
        pagination
        paginationMode="server"
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={(params) => handlePageChange(params.page)}
        rowCount={totalCount}
        onRowClick={(params) => {
          setSelectedBudgetData(createBudgetGroup(params.row));
          setOpen(true);
        }}
        filterMode="server"
        onFilterModelChange={handleFilterChange}
      />
      <BudgetModal />
    </>
  );
}
